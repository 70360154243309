
    .live-content {
        height: 100%;
        width: 100%;
        background: #070932;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .tag-item {
        position: absolute;
        top: -22px;
        left: 20px;
        z-index: 1;

        .item-content {
            box-shadow: 0px 0px 21px 0px rgba(0, 3, 85, 0.66);
            position: relative;

            img {
                width: 113px;
                height: 131px;
            }

            .item-text {
                position: absolute;
                width: 40px;
                top: 34px;
                left: 38px;
                color: #fff;
                font-size: 18px;
                font-weight: bold;
                word-break: break-all;
                cursor: pointer;
            }
        }
    }

    .resume-title {
        color: #fff;
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        line-height: 90px;
        position: relative;

        &:before {
            content: '';
            width: 391px;
            height: 75px;
            background: url("../../assets/image/student/title_left.png") no-repeat;
            position: absolute;
            left: calc(50% - 391px - 90px - 10px);
            top: 50%;
            transform: translateY(-50%);
        }

        &:after {
            content: '';
            width: 391px;
            height: 75px;
            background: url("../../assets/image/student/title_right.png") no-repeat;
            position: absolute;
            right: calc(50% - 391px - 90px - 10px);
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .main-content {
        flex: 1;
        height: 1%;
        margin: 20px;
        display: flex;

        .content-item {
            border-radius: 20px;
        }

        .left-content {
            margin-right: 20px;
            width: 50%;
            background: #0C0E3F;
            display: flex;
            flex-direction: column;
            position: relative;

            .top-title {
                margin-top: 42px;
                text-align: center;

                img {
                    width: 41px;
                    height: 15px;
                }

                .top-text {
                    margin: 0 21px;
                    font-size: 24px;
                    color: #FFFFFF;
                }
            }

            .script-content {
                margin: 51px 0 20px;
                flex: 1;
                display: flex;
                flex-direction: column;
                color: #fff;
                overflow: hidden;

                ::v-deep .el-scrollbar {
                    flex: 1;
                    height: 1%;

                    .el-scrollbar__wrap {
                        overflow-x: hidden;

                        .el-scrollbar__view {
                            height: 100%;
                            // margin-left: 70px;
                        }
                    }

                    .el-scrollbar__bar.is-vertical {
                        .el-scrollbar__thumb {
                            width: 8px;
                            background: #4B45FF;
                        }
                    }
                }

                .script-item {
                    position: relative;
                    cursor: grab;

                    .title-content {
                        display: flex;
                        align-items: center;
                        font-size: 18px;

                        .title-left {
                            width: 26px;
                            height: 26px;
                            background: #BFBFBF;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .title-right {
                            margin-left: 12px;
                        }
                    }

                    .active-title {

                        .title-left {
                            background: #F49D19;
                        }
                    }

                    .children-content {
                        margin-left: 12px;
                        border-left: 1px dashed #444797;
                        padding: 20px 0 20px 28px;

                        .script-subItem {
                            /*margin-top: 20px;*/
                            width: 90%;
                            word-break: break-all;
                            line-height: 40px;
                            opacity: 0.3;
                            padding: 10px;

                            .subItem-time {
                                position: absolute;
                                left: -75px;
                                text-align: right;
                                width: 60px;
                            }
                        }
                    }
                }

                .script-title {
                    // margin-left: 60px;
                    font-size: 18px;
                }

                .script-inner {
                    /*height: 50%;*/
                    width: calc(100% - 60px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 24px;
                    text-align: left;
                    margin-left: 30px;

                    ::v-deep .el-scrollbar__wrap {
                        width: 100%;
                        margin-right: -40px !important;

                        .el-scrollbar__view {
                            height: unset;
                        }
                    }

                    .inner-main {
                        // width: 100%;
                        // margin-top: 60px;
                        margin-left: 100px;
                        margin-top: 30px;
                    }
                }
            }
        }
    }

    .data-content {
        display: flex;
        margin: 10px 20px;


        .data-content-item {
            margin-right: 21px;
            width: calc(100% / 5);
            color: #FFFFFF;
            border-radius: 20px;
            min-height: 80px;
            z-index: 1;
            height: 143px;
            position: relative;

            &:last-child {
                margin-right: 0;
            }

            .middle-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                margin: 20px 0;
                height: calc(100% - 40px);

                .top-item {
                    font-size: 16px;
                    opacity: 0.8;
                }

                .middle-item {
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    margin-top: 20px;
                    display: flex;

                    i {
                        font-size: 32px;
                        margin-right: 10px;
                        color: #FF3E6C;
                    }
                }

                .bottom-item {
                    font-size: 16px;
                    // font-weight: bold;
                    margin-top: 20px;
                }
            }

            .icon-box {
                position: absolute;
                top: 22px;
                left: 20px;

                i {
                    font-size: 48px;
                }
            }

            .shadow-icon {
                position: absolute;
                top: 0;
                right: 0;

                i {
                    font-size: 140px;
                    opacity: 0.1;
                }
            }
        }
    }

    .shadow-3 {
        background: rgba(0, 0, 0, .3);
    }

    .isCurrent {
        opacity: 1 !important;
        font-size: 16px;
        color: #12C4C4;
        background: rgba(10, 8, 73, 0.5);
    }

    .progress-content {
        width: 100%;
        height: 100px;
        background: #0C0E3F;
        color: #fff;
        display: flex;

        .progress-inner {
            display: flex;
            width: 100%;
            overflow-x: auto;

            .progress-item {
                flex: 1;
                flex-basis: auto !important;
                flex-shrink: 0;
                flex-grow: 0;
                position: relative;
                margin-top: 20px;
                min-width: 100px;

                .item-line {
                    height: 18px;
                    width: 1px;
                    background: #F7F8FC;
                    top: 10px;
                }

                .item-line-bottom {
                    width: 100%;
                    position: absolute;
                    border-color: inherit;
                    background: #F7F8FC;
                    height: 2px;
                    top: 44px;
                    left: 0;
                    right: 0;
                }

                .item-time {
                    margin-left: -15px;
                    margin-bottom: 10px;
                    cursor: pointer;
                }

                &:first-child {
                    padding-left: 40px;

                    .item-line-bottom {
                        left: 40px;
                    }
                }

                &:last-child {
                    .item-line-bottom {
                        display: none;
                    }
                }
            }
        }
    }

    .color-word {
        color: #4B45FF;
    }

    ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb { //滑块部分
  border-radius: 5px;
  background-color: #4B45FF;
}
::-webkit-scrollbar-track { //轨道部分
  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: #1e2061;
  border-radius: 5px;

}
